import config from '@utils/config';

const SE_MIN_SHIPPING_COST = 39;
const NO_MIN_SHIPPING_COST = 89;
const SE_FREE_SHIPPING_THRESHOLD = 495; // Temporary hardcoded value
const NO_FREE_SHIPPING_THRESHOLD = 495; // Temporary hardcoded value

const getShippingCost = (totalCost: number) => {
  const { locale } = config;
  if (locale === 'sv-se') {
    if (totalCost >= SE_FREE_SHIPPING_THRESHOLD) {
      return 0;
    } else {
      return SE_MIN_SHIPPING_COST;
    }
  }

  if (locale === 'nb-no') {
    if (totalCost >= NO_FREE_SHIPPING_THRESHOLD) {
      return 0;
    } else {
      return NO_MIN_SHIPPING_COST;
    }
  }

  return null;
};

export {
  SE_FREE_SHIPPING_THRESHOLD,
  SE_MIN_SHIPPING_COST,
  NO_FREE_SHIPPING_THRESHOLD,
  NO_MIN_SHIPPING_COST,
  getShippingCost,
};
