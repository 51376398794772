'use client';

import { Cart as UICart } from '@ngg/ui';
import React, { useEffect, useMemo, useState } from 'react';
import useCart from '@utils/hooks/cart/useCart';
import useCartRemove from '@utils/hooks/cart/useCartRemove';
import { Button } from '@ngg/components';
import useMutateCart from '@utils/hooks/cart/useMutateCart';
import { cn } from '@ngg/storefront-utils';
import { formatPrice } from '@utils/currency/formatPrice';
import Link from '../Link/Link';
import Icon from '../icon/icon';
import UICartItem from './components/CartItem';
import config from '@utils/config';
import { useMenuStore } from '@stores/menu.store';
import CartImage from '@ui/components/cart/components/CartImage';
import useDictionary from '@utils/hooks/useDictionary';
import Quantity from './components/quantity';
import { gTagViewCart } from '@utils/googleTags';
import { getShippingCost } from '@utils/cart/shipping';
import { useDeliveryStore } from '@ui/components/checkout/delivery/useDeliveryStore';
import useCartDelivery from '@utils/hooks/cart/useCartDelivery';
import Paragraph from '../Paragraph/Paragraph';
import { Heading } from '../Heading/Heading';
import Badge from '@ui/components/cart/components/badge';

export default function LannaCart() {
  const [open, setOpen] = useState(false);
  const { setDrawerIsOpen } = useMenuStore();
  const { locale } = config;

  const dictionary = useDictionary({
    keys: [
      'emptyCart',
      'startShopping',
      'total',
      'receivedDiscount',
      'goToCheckout',
      'yourCart',
      'inStock',
      'inStockSoon',
      'outOfStock',
      'lessThanInStock',
      'madeToOrder',
      'estimatedDeliveryTime',
      'week',
      'soldOut',
      'shipping',
      'from',
    ],
  });

  const { data: cartData, isSuccess } = useCart(locale);
  const { zipCode } = useDeliveryStore();
  const { data: deliveryData } = useCartDelivery({
    cartItems: cartData?.cart?.items,
    locale,
    zipCode,
  });

  const remove = useCartRemove(locale);
  const update = useMutateCart({
    type: 'update',
    locale,
  });

  const cartIsRemoving = remove.isPending;

  const numberOfItems =
    cartData?.cart?.items.reduce((a, b) => a + b.quantity, 0) ?? 0;

  const totalCostItems = useMemo(
    () =>
      Number(
        cartData?.cart?.items?.reduce(
          (prev, { quantity, price }) => prev + quantity * price,
          0,
        ),
      ),
    [cartData?.cart?.items],
  );

  const actualShippingCost = useMemo(() => {
    const selectedDeliveryOptionName =
      cartData?.cart?.meta?.NSHIFT_SHIPPING_TYPE;
    const selectedDeliveryOptionStillAvailable =
      deliveryData?.deliveries?.options?.some(
        (option) => option?.name === selectedDeliveryOptionName,
      );

    const actualShippingCost =
      selectedDeliveryOptionStillAvailable &&
      cartData?.cart?.totalFreightInclVat;
    return actualShippingCost;
  }, [
    cartData?.cart?.meta?.NSHIFT_SHIPPING_TYPE,
    cartData?.cart?.totalFreightInclVat,
    deliveryData?.deliveries?.options,
  ]);
  const fallbackShippingCost = getShippingCost(totalCostItems);

  useEffect(() => {
    setDrawerIsOpen(open);
  }, [open, setDrawerIsOpen]);

  return (
    <>
      <UICart
        open={open}
        hideCart={() => setOpen(!open)}
        classNames={{ panel: 'max-sm:max-w-[326px]' }}
        dictionary={{
          yourCart: (
            <Heading className="font-medium">{dictionary?.yourCart}</Heading>
          ) as unknown as string,
        }}
        EmptyCart={
          <div className="p-4 pt-3">
            <div className="mb-6 text-left">{dictionary.emptyCart}</div>
            <div>
              <Button
                type="button"
                className="btn-primary disabled:border-gray-150 disabled:bg-grey-150 disabled:text-grey-300 h-[40px] grow bg-black tracking-wide text-white disabled:opacity-100 md:text-base"
                fullWidth
                onClick={() => setOpen(false)}>
                <Paragraph>{dictionary.startShopping}</Paragraph>
              </Button>
            </div>
          </div>
        }
        isEmpty={numberOfItems === 0}
        CartFooter={
          <div className="flex flex-col gap-y-2 border-t bg-beige p-3 pb-6">
            {actualShippingCost || actualShippingCost === 0 ? (
              <div className="flex justify-between text-sm">
                <Paragraph>{dictionary.shipping}</Paragraph>
                <Paragraph className="font-semibold">
                  {formatPrice(actualShippingCost, 'fixed', true)}
                </Paragraph>
              </div>
            ) : fallbackShippingCost || fallbackShippingCost === 0 ? (
              <div className="flex justify-between text-sm">
                <Paragraph>{dictionary.shipping}</Paragraph>
                <Paragraph className="font-sans font-semibold">
                  {`${dictionary.from} ${formatPrice(
                    fallbackShippingCost,
                    'fixed',
                    true,
                  )}`}
                </Paragraph>
              </div>
            ) : null}

            {cartData?.cart?.basketPromotions &&
              cartData?.cart?.basketPromotions[0]?.appliedAmountIncVat && (
                <div className="flex justify-between text-sm">
                  <Paragraph>{dictionary.receivedDiscount}</Paragraph>
                  <Paragraph className="text-sm">
                    {`- ${formatPrice(
                      Number(
                        cartData?.cart?.basketPromotions[0]
                          ?.appliedAmountIncVat ?? 0,
                      ),
                    )}`}
                  </Paragraph>
                </div>
              )}
            <div className="flex justify-between text-sm">
              <Paragraph>{dictionary.total}</Paragraph>
              <Paragraph className="font-sans font-semibold">
                {actualShippingCost || actualShippingCost === 0
                  ? formatPrice(cartData?.cart?.totalGrossAmount)
                  : formatPrice(totalCostItems)}
              </Paragraph>
            </div>
            <div>
              <Link href={`/checkout`}>
                <Button
                  type="button"
                  className="btn-primary disabled:border-gray-150 disabled:bg-grey-150 disabled:text-grey-300 h-[40px] grow bg-black tracking-wide text-white disabled:opacity-100 md:text-base"
                  fullWidth>
                  <Paragraph>{dictionary.goToCheckout}</Paragraph>
                </Button>
              </Link>
            </div>
            <div className="mt-2 flex justify-center">
              <Icon name="payment" size={12} className="w-full" />
            </div>
          </div>
        }>
        {isSuccess && (
          <div>
            {cartData?.cart?.items?.map((item) => (
              <UICartItem
                disabled={cartIsRemoving}
                key={item.partNo}
                title={item.name}
                brand={item.brand}
                dictionary={dictionary}
                StockStatusText={item?.stockStatus?.statusCode}
                ImageComponent={
                  <CartImage imageKey={item.imageKey} alt={item.partNo} />
                }
                item={item}
                ActionComponent={
                  <Quantity
                    quantity={item.quantity}
                    update={update.mutate}
                    remove={remove.mutate}
                    partNo={item.partNo}
                    disabled={cartIsRemoving}
                    bottomContent={({ quantity }) =>
                      typeof item?.price === 'number' && (
                        <div className="flex flex-col text-right">
                          {item.priceOriginal &&
                            item.priceOriginal !== item.price && (
                              <Paragraph className="text-grey-4 text-right text-sm line-through">
                                {formatPrice(quantity * item.priceOriginal)}
                              </Paragraph>
                            )}
                          <Paragraph className="text-right text-sm font-semibold">
                            {formatPrice(quantity * item.price)}
                          </Paragraph>
                        </div>
                      )
                    }
                  />
                }
                onRemove={() => {
                  remove.mutate(item.partNo);
                }}
              />
            ))}
          </div>
        )}
      </UICart>
      <button
        className="cursor-pointer p-4"
        onClick={() => {
          if (cartData) gTagViewCart(cartData?.cart);
          setOpen(true);
        }}
        data-testid="cart-icon-button">
        <Badge badgeContent={numberOfItems}>
          <Icon name="shoppingBag" className={cn('h-6 w-6', 'block')} />
        </Badge>
      </button>
    </>
  );
}
