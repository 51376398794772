import type { FocusEvent, InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { useSearch } from './Context';
import { cn } from '@ngg/storefront-utils';
import Icon from '../../icon/icon';
import { useSearchStore } from '@utils/hooks/useSearchStore';

type SearchInputProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  containerClassName?: string;
  iconClassName?: string;
};
const Input = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    { placeholder, iconClassName, containerClassName, className, ...props },
    ref,
  ) => {
    return (
      <div
        className={cn('relative flex items-center px-2', containerClassName)}>
        <Icon name="search" size={24} className={iconClassName} />
        <input
          ref={ref}
          className={cn([
            'focus:border-gray-150 w-full border border-transparent p-2 pl-1 font-sans text-base placeholder:text-sm placeholder:text-gray-600 focus:outline-none',
            className,
          ])}
          placeholder={placeholder}
          {...props}
        />
      </div>
    );
  },
);

Input.displayName = 'Input';

const SearchInput = ({
  className,
  onBlur: blurHandler = () => null,
  onFocus: focusHandler = () => null,
  value,
  children,
  ...props
}: SearchInputProps) => {
  const { selected, onBlur, onFocus, onKeyDown, inputRef } = useSearch();
  const searchFocus = useSearchStore((state) => state.searchFocus);

  const handleBlur = (event: FocusEvent<HTMLInputElement>) =>
    onBlur(event).then(blurHandler);
  const handleFocus = (event: FocusEvent<HTMLInputElement>) =>
    onFocus(event).then(focusHandler);
  return (
    <Input
      data-testid="SearchInput"
      ref={inputRef}
      value={value}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={onKeyDown}
      className={cn([className, !searchFocus && 'text-grey-600'])}
      data-active-option={selected}
      {...props}>
      {children}
    </Input>
  );
};

SearchInput.displayName = 'SearchInput';

export default SearchInput;
