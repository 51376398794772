'use client';

import { setCookie } from 'cookies-next';
import { useCallback, useState } from 'react';
import useWindowSize from '@utils/useWindowSize';
import type {
  CampaignBarFragment,
  CampaignFragment,
  Maybe,
  PageFragment,
} from '@business/gql/graphql';
import RichTextLink from '../RichTextLink/RichtTextLink';
import { cn } from '@ngg/storefront-utils';
import Icon from '../icon/icon';
import config from '@utils/config';
import Paragraph from '../Paragraph/Paragraph';

const getCampaignBarBackgroundColor = (color?: string) => {
  if (color === 'red') {
    return 'bg-yellow text-yellow-contrast';
  }

  if (color === 'green') {
    return 'bg-green text-green-contrast';
  }

  return 'bg-primary-yellow text-yellow-contrast';
};

type Props = {
  campaignBar?: Maybe<CampaignBarFragment>;
  hasCampaignBarCookie: boolean;
};

type InternalLink = PageFragment | CampaignFragment;

export default function CampaignBarContent(props: Props) {
  const { locale } = config;
  const { campaignBar: campaignBarData, hasCampaignBarCookie } = props;

  const { isBreakpoint } = useWindowSize('md');
  const isMobile = !isBreakpoint;

  const [campaignBarCookie, setCampaignBarCookie] =
    useState(hasCampaignBarCookie);

  const onHide = useCallback(() => {
    setCookie('lm_campaign_bar', true); // Session cookie
    setCampaignBarCookie(true);
  }, []);

  const bgColor = getCampaignBarBackgroundColor(
    campaignBarData?.backgroundColor ?? '',
  );

  // For now we should only show one item in campaign bar
  const campaign = campaignBarData?.navigationLinksCollection?.items[0];

  if (!campaign || !campaign?.title || campaignBarCookie) return null;

  return (
    <div
      id="campaign-bar"
      className={cn(
        'relative z-40 flex min-h-[24px] items-center justify-center overflow-hidden py-1',
        'md:min-h-[32px] md:z-0 md:py-2',
        campaignBarData?.backgroundColor && bgColor,
      )}>
      {campaign && (
        <RichTextLink
          locale={locale}
          className="flex-1 text-center"
          href={
            campaign?.externalLink ??
            (campaign?.internalLink as InternalLink)?.slug ??
            ''
          }>
          <div className="flex w-full flex-row items-center justify-center">
            <Paragraph className="text-xs font-medium leading-none lg:hover:underline">
              {campaign?.title}
            </Paragraph>
            <Icon name="rightArrowWithTail" className="ml-1" size={12} />
          </div>
        </RichTextLink>
      )}
      <button
        type="button"
        className="right-0 flex-shrink-0 p-2"
        onClick={onHide}>
        <Icon name="cross" size={isMobile ? 12 : 16} />
      </button>
    </div>
  );
}
