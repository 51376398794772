'use client';

import debounce from 'lodash.debounce';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import {
  ChangeEvent,
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';
import SearchComponent from './components/Search';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchStore } from '@utils/hooks/useSearchStore';
import searchVariants from './Search.styles';
import { cn } from '@ngg/storefront-utils';
import { useMenuStore } from '@stores/menu.store';
import useAutocomplete from '@utils/hooks/useAutocomplete';
import { getSearchPathname } from '@utils/url/getSearchPathname';
import useDictionary from '@utils/hooks/useDictionary';
import { Heading } from '../Heading/Heading';
import { findAutocompleteMatch } from '@utils/findAutocompleteMatch';

const getStylizedText = (text = '', query = '') => {
  const highlight = text.replace(
    new RegExp(query, 'i'),
    (match: string) => `***${match}***`,
  );
  const [prefix, match, suffix] = highlight.split('***');
  return (
    <>
      <span className="pointer-events-none">{prefix}</span>
      <span className="pointer-events-none font-semibold">{match}</span>
      <span className="pointer-events-none">{suffix}</span>
    </>
  );
};

export default function Search({
  locale,
  onBlur: blurHandler = () => null,
  suggestions: showSuggestions = true,
  classNames,
}: {
  locale: string;
  suggestions?: boolean;
  classNames?: {
    root?: string;
    input?: string;
    inputContainer?: string;
    icon?: string;
  };
} & InputHTMLAttributes<HTMLInputElement>) {
  const { showSearchAndCampaignBar } = useMenuStore();
  const pathname = usePathname();
  const router = useRouter();
  const params = useSearchParams();

  const queryClient = useQueryClient();

  const setSearchFocus = useSearchStore((state) => state.setSearchFocus);
  const [searchValue, setSearchValue] = useState(params.get('query') || '');

  const searchQuery = useSearchStore((state) => state.searchQuery);
  const setSearchQuery = useSearchStore((state) => state.setSearchQuery);

  const { data } = useAutocomplete({
    query: searchQuery,
    locale,
  });

  const debouncedSetSearchQuery = useMemo(
    () => debounce(setSearchQuery, 300),
    [setSearchQuery],
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    debouncedSetSearchQuery(event.target.value);
  };

  const onSearch = (str: string) => {
    const trimmedSearchQuery = str.trim();

    if (trimmedSearchQuery.length === 0) {
      return;
    }

    void queryClient.cancelQueries({
      queryKey: ['autocomplete', searchQuery],
    });

    const match = findAutocompleteMatch({
      query: str,
      data,
    });

    setSearchFocus(false);
    setSearchQuery('');

    if (
      match &&
      (match.contentType === 'brand' || match.contentType === 'designer')
    ) {
      router.push(match.contentUrl);
      setSearchValue('');
      return;
    }

    if (match && match.contentType === 'category') {
      setSearchValue('');
      router.push(match.contentUrl);
      return;
    }

    const newSearchParams = new URLSearchParams(window.location.search);
    const searchPath = getSearchPathname();
    ['category', 'designer', 'brand', 'price', 'sort'].forEach((key) => {
      newSearchParams.delete(key);
    });
    newSearchParams.set('query', str);
    router.push(searchPath + '?' + newSearchParams.toString());
  };

  useEffect(() => {
    if (pathname.includes(getSearchPathname())) {
      setSearchValue(params.get('query') || '');
    } else {
      setSearchValue('');
    }

    setSearchFocus(false);
  }, [params, pathname, setSearchFocus, setSearchValue]);
  const dictionary = useDictionary({
    keys: [
      'searchTerm',
      'searchSuggestions',
      'brands',
      'categories',
      'designers',
    ],
  });

  return (
    <SearchComponent.Root
      onSearch={onSearch}
      className={cn([searchVariants().searchComponent(), classNames?.root])}
      locale={locale}>
      <SearchComponent.Input
        value={searchValue}
        onBlur={blurHandler}
        onChange={handleChange}
        placeholder={dictionary?.searchTerm}
        containerClassName={classNames?.inputContainer}
        iconClassName={classNames?.icon}
        className={classNames?.input}
      />
      {showSuggestions && showSearchAndCampaignBar && searchValue ? (
        <SearchComponent.List className={searchVariants().listContainer()}>
          {data?.queries?.length ? (
            <section className={searchVariants().suggestionContainer()}>
              <Heading as="h6" className={searchVariants().heading()}>
                {dictionary?.searchSuggestions}
              </Heading>
              {data.queries.map((item) => (
                <SearchComponent.Suggestion
                  className={searchVariants().suggestion()}
                  key={item}
                  value={item || ''}>
                  {getStylizedText(item || '', searchQuery)}
                </SearchComponent.Suggestion>
              ))}
            </section>
          ) : null}
          {data?.brands?.length ? (
            <section className={searchVariants().suggestionContainer()}>
              <Heading as="h6" className={searchVariants().heading()}>
                {dictionary?.brands}
              </Heading>
              {data.brands.map((item) => (
                <SearchComponent.Suggestion
                  className={searchVariants().suggestion()}
                  type="link"
                  key={item?.Id}
                  value={item?.contentName || ''}>
                  {getStylizedText(item?.contentName, searchQuery)}
                </SearchComponent.Suggestion>
              ))}
            </section>
          ) : null}
          {data?.categories?.length ? (
            <section className={searchVariants().suggestionContainer()}>
              <Heading as="h6" className={searchVariants().heading()}>
                {dictionary?.categories}
              </Heading>
              {data.categories.map((item) => (
                <SearchComponent.Suggestion
                  className="px-9 py-1"
                  type="link"
                  key={item?.Id}
                  value={item?.contentName || ''}>
                  {getStylizedText(item?.contentName, searchQuery)}
                </SearchComponent.Suggestion>
              ))}
            </section>
          ) : null}
          {data?.designers?.length ? (
            <section className={searchVariants().suggestionContainer()}>
              <Heading as="h6" className={searchVariants().heading()}>
                {dictionary?.designers}
              </Heading>
              {data.designers.map((item) => (
                <SearchComponent.Suggestion
                  className={searchVariants().suggestion()}
                  type="link"
                  key={item?.Id}
                  value={item?.contentName || ''}>
                  {getStylizedText(item?.contentName, searchQuery)}
                </SearchComponent.Suggestion>
              ))}
            </section>
          ) : null}
          {/* {data?.scopes?.length ? ( // TODO implement when NG has an approach to scopes
            <section className="border-b py-1">
              <Heading as="h6" className="px-9 py-1 uppercase">Scopes uppdatera</Heading>
              {data.scopes.map((item, idx) => (
                <SearchComponent.Suggestion
                  className="px-9 py-1"
                  key={(item?.query || '') + idx}
                  value={`${item?.query}&category=${item?.category}`}>
                  <>
                    {getStylizedText(item?.query || '', searchQuery)}
                    &nbsp;in&nbsp;
                    {item?.category?.toLowerCase()}
                  </>
                </SearchComponent.Suggestion>
              ))}
            </section>
          ) : null} */}
        </SearchComponent.List>
      ) : null}
    </SearchComponent.Root>
  );
}
