import type {
  Dispatch,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  Ref,
  RefObject,
  SetStateAction,
} from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { SearchProps } from './Search';
import { useSearchStore } from '@utils/hooks/useSearchStore';

interface SearchContextProps extends SearchProps {
  wrapperRef: RefObject<HTMLDivElement>;
}

export type UseSearchContextProps = {
  selected: string | null;
  observer?: IntersectionObserver;
  inputRef?: Ref<HTMLInputElement>;
  wrapperRef?: Ref<HTMLDivElement>;
  onBlur: (
    event: FocusEvent<HTMLInputElement>,
  ) => Promise<FocusEvent<HTMLInputElement>>;
  onFocus: (
    event: FocusEvent<HTMLInputElement>,
  ) => Promise<FocusEvent<HTMLInputElement>>;
  onClick: (event: MouseEvent<HTMLLIElement>) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  setSelected: Dispatch<SetStateAction<string | null>>;
};

const Context = createContext<UseSearchContextProps>({
  selected: null,
  observer: undefined,
  inputRef: undefined,
  wrapperRef: undefined,
  onBlur: (event: FocusEvent<HTMLInputElement>) => Promise.resolve(event),
  onFocus: (event: FocusEvent<HTMLInputElement>) => Promise.resolve(event),
  onClick: () => null,
  onKeyDown: () => null,
  setSelected: () => null,
});

const SearchContextProvider = ({
  onChange = () => null,
  onSearch,
  wrapperRef,
  children,
}: SearchContextProps) => {
  const [selected, setSelected] = useState<string | null>(null);

  const callback = (nodes: IntersectionObserverEntry[]) =>
    nodes.forEach(
      (node) =>
        !node.isIntersecting &&
        node.target.scrollIntoView({
          block: 'nearest',
        }),
    );

  const [observer, setObserver] = useState<IntersectionObserver>();

  useEffect(() => {
    setObserver(
      new IntersectionObserver(callback, {
        root: wrapperRef?.current,
        rootMargin: '40px 0px 0px 0px',
        threshold: 1,
      }),
    );
  }, [wrapperRef]);

  const setSearchFocus = useSearchStore((state) => state.setSearchFocus);
  const inputRef = useRef<HTMLInputElement>(null);

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) =>
      new Promise<FocusEvent<HTMLInputElement>>((resolve) => {
        setTimeout(() => {
          setSearchFocus(false);
          resolve(event);
        }, 100);
      }),
    [setSearchFocus],
  );

  const onFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) =>
      new Promise<FocusEvent<HTMLInputElement>>((resolve) => {
        setSearchFocus(true);
        resolve(event);
      }),
    [setSearchFocus],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (!wrapperRef?.current) {
        return;
      }

      const nodes = wrapperRef?.current.querySelectorAll(
        '*[role="option"]:not([aria-disabled="true"])',
      );

      const data = Array.from(nodes).map((el) =>
        (el as Element).getAttribute('value'),
      );

      switch (event.key) {
        case 'ArrowDown': {
          event.preventDefault();
          event.stopPropagation();

          const current = data.indexOf(selected);

          setSelected(data[Math.min(data.length - 1, current + 1)]);

          break;
        }
        case 'PageDown': {
          event.preventDefault();
          event.stopPropagation();

          const current = data.indexOf(selected);

          setSelected(data[Math.min(data.length - 1, current + 5)]);

          break;
        }
        case 'End': {
          event.preventDefault();
          event.stopPropagation();

          setSelected(data[data.length - 1]);

          break;
        }

        case 'ArrowUp': {
          event.preventDefault();
          event.stopPropagation();

          const current = data.indexOf(selected);
          if (current > 0) {
            setSelected(data[Math.max(-1, current - 1)]);
          } else {
            setSelected(null);
          }
          break;
        }

        case 'PageUp': {
          event.preventDefault();
          event.stopPropagation();

          const current = data.indexOf(selected);

          setSelected(data[Math.max(0, current - 5)]);
          break;
        }

        case 'Home': {
          event.preventDefault();
          event.stopPropagation();
          setSelected(data[0]);
          break;
        }

        case 'Escape': {
          event.preventDefault();
          event.stopPropagation();
          (event.target as HTMLElement).blur();
          break;
        }

        case 'Enter': {
          event.preventDefault();
          event.stopPropagation();

          const input = event.target as HTMLInputElement;

          if (selected && data.includes(selected)) {
            onSearch(selected);
          } else {
            setSelected(null);
            onSearch(input.value);
          }

          input.blur();

          break;
        }

        default:
          break;
      }
    },
    [selected, wrapperRef, onSearch],
  );

  useEffect(() => {
    if (onChange) {
      onChange(selected);
    }
  }, [onChange, selected]);

  const onClick = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      const value = (event.currentTarget as HTMLElement).getAttribute('value');

      if (value) {
        onSearch(value);
      }

      (document.activeElement as HTMLElement)?.blur();
    },
    [onSearch],
  );

  const value = useMemo(
    () => ({
      observer,
      inputRef,
      wrapperRef,
      selected,
      setSelected,
      onBlur,
      onFocus,
      onClick,
      onKeyDown,
    }),
    [
      observer,
      inputRef,
      wrapperRef,
      selected,
      setSelected,
      onBlur,
      onFocus,
      onClick,
      onKeyDown,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useSearch = () => {
  const context = useContext(Context);

  if (!context) throw new Error('useSearch must be used within a SearchContextProvider');

  return context;
};

export default SearchContextProvider;
